import {
  ADD_USER,
  UPDATE_USER,
  REMOVE_USER,
  ADD_TASK,
  REMOVE_TASK,
  UPDATE_TASK,
} from "./actionType";
export const adduser = (user) => ({
  type: ADD_USER,
  payload: user,
});

export const updateUsers = (userId) => {
  return {
    type: UPDATE_USER,
    payload: userId,
  };
};

export const removeUser = (userId) => ({
  type: REMOVE_USER,
  payload: userId,
});

// action type of task

export const addTask = (task) => ({
  type: ADD_TASK,
  payload: task,
});

export const removeTask = (taskId) => ({
  type: REMOVE_TASK,
  payload: taskId,
});

export const updateTask = (taskId) => ({
    type: UPDATE_TASK , 
    payload: taskId
});
