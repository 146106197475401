import { createBrowserRouter } from "react-router-dom";
import AddUser from "../app/users/AddUser";
import UserUpdate from "../app/users/UserUpdate";
import Hero from "../sections/heroSection/Hero.jsx";
import AddTasks from "../app/tasks/AddTasks";
import Root from "../Root";
import UpdateTask from "../app/tasks/UpdateTask.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Hero />,
        children: [
          {
            path: "/users",
            element: <AddUser />,
          },
          {
            path: "/add-task",
            element: <AddTasks />,
          },
          {
            path:'/Task-Update/:index' , 
            element:<UpdateTask />
          }
        ],
      },
    ],
  },
  {
    path: "/User-Update/:index",
    element: <UserUpdate />,
  },

 
]);
export default router;
