import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateTask } from "../../redux/action";
import TaskUpdateForm from "../../components/taskForm/TaskUpdateForm";

export default function UpdateTask() {
  const dispatch = useDispatch();
  const { index } = useParams();

  const tasks = useSelector((state) => state.tasks.tasks);
  const [error, setError] = useState("");
  const [task, setTask] = useState({
    id: "",
    name: "",
    userId: "",
    description: "",
    date: "",
  });

  useEffect(() => {
    const foundTask = tasks.find((u, i) => i === parseInt(index));
    if (foundTask) {
      setTask(foundTask);
    }
  }, [index, tasks]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTask({ ...task, [name]: value });
  };

  const editTask = (e) => {
    e.preventDefault();
    dispatch(updateTask(task));
  };

  return (
    <div className="d-flex justify-content-center">
      <TaskUpdateForm
        task={task}
        editTask={editTask}
        handleChange={handleChange}
        error={error}
      />
    </div>
  );
}
